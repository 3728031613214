import React, {useEffect, useState} from 'react';

import api from '../services/api'
import {Trans, useTranslation} from "react-i18next";
import {getLng} from "../helpers";

const Conteudo = () => {
    const {  i18n } = useTranslation();

    const activeLng = getLng(i18n)

    const [state, setState] = useState({
        news: []
    });

        useEffect(() => {
            api.get('/p/reports', {
                params: {
                    page: 1
                }
            })
                .then(({ data }) => {
                    const aux = data.docs.map(item => {
                        if (item.portuguese && item.portuguese.title) {
                            return { id: item.id, ...item.portuguese, attachment: item.attachmentSmall }
                        }
                        if (item.english && item.english.title) {
                            return { id: item.id, ...item.english, attachment: item.attachmentSmall }
                        }
                    });
                    setState({ news: aux })
                })
        }, [setState])

        const news1 = state.news[0] || {};
        const news2 = state.news[1] || {};
        const news3 = state.news[2] || {};

    return (
      <section className="body-content">
      <div  className=" text-center vertical-align parallax-window-big" data-parallax="scroll" data-image-src="assets/img/parallax/maior-investidor.jpg" >
          <div className="container-mid">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="banner-title light-txt">
                              <h1  style={{textShadow: '-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5), -2px -20px 1px rgba(63,63,63,0)'}} className="text-uppercase"><Trans>JIVE E MAUÁ AGORA SÃO JIVEMAUÁ.</Trans></h1>
                              <h3 style={{textShadow: '-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5), -2px -20px 1px rgba(63,63,63,0)'}} className="text-uppercase"><Trans>EM BREVE, EM NOVO SITE</Trans></h3>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


      {/* <div className="full-width float-left parallax-window" data-parallax="scroll" data-image-src="assets/img/parallax/jive-gestora-de-investimentos.jpg"> */}
      <div className="full-width float-left parallax-window">
            <div className="container-fluid">
                    <div className="heading-title-alt text-center m-bot-0 inline-block">
                        <p className="heading-sub-title p-top-10 txt-xl half-txt" style={{width: 800}}>
                          <Trans>JiveMauá é uma gestora de investimentos alternativos que nasceu da fusão da <i>Jive Investments</i> com a <i>Mauá Capital.</i></Trans>
                        </p>
                    </div>
                    <div className="heading-title-alt text-center m-bot-0 inline-block">    
                        <p className="heading-sub-title p-top-10 txt-xl half-txt" style={{width: 800}}>
                          <Trans>Com mais de 18 anos de experiências combinadas, somos uma gestora de crédito que possui know-how para originar e gerir de forma ativa as próprias operações, com profissionais experientes em avaliar riscos e oportunidades.</Trans>
                        </p>
                    </div>
                    <div className="heading-title-alt text-center m-bot-0 inline-block">    
                        <p className="heading-sub-title p-top-10 txt-xl half-txt" style={{width: 800}}>
                          <Trans>A JiveMauá tem hoje mais de R$18,6 bilhões sob gestão e mais de 250 mil investidores que confiam em nossa estratégia.</Trans>
                          </p>
                    </div>
            </div>
        </div>

      {/* <div className="page-content" id="estrategias">
          <div className="container-fluid">
              <div className="row ">

                  <div className="heading-title text-center">
                      <h3 className="text-uppercase"> Principais Estratégias</h3>
                  </div>
                  <div className="col-md-3">
                      <div className="featured-item feature-bg-box gray-bg text-center m-bot-0 inline-block radius-less box-estrategia">
                          <div className="icon">
                              <i className="fa fa-file-text-o"></i>
                          </div>
                          <div className="desc p-top-30">
                              Investimentos em carteiras créditos individuais de créditos inadimplidos contra empresas de médio e grande porte.
                          </div>
                      </div>
                  </div>
                  <div className="col-md-3 box-estrategia-margin">
                      <div className="featured-item feature-bg-box gray-bg text-center m-bot-0 inline-block radius-less box-estrategia">
                          <div className="icon">
                              <i className="fa fa-file-archive-o"></i>
                          </div>
                          <div className="desc p-top-30">
                              Investimentos em precatórios, pré-precatórios e direitos creditórios contra empresas públicas e de economia mista e grandes empresas privadas.
                          </div>
                      </div>
                  </div>
                  <div className="col-md-3 box-estrategia-margin">
                      <div className="featured-item feature-bg-box gray-bg text-center m-bot-0 inline-block radius-less box-estrategia">
                          <div className="icon">
                              <i className="fa fa-building-o"></i>
                          </div>
                          <div className="desc p-top-30">
                              Investimentos em ativos imobiliários, incluindo ativos estressados.
                          </div>
                      </div>
                  </div>
                  <div className="col-md-3 box-estrategia-margin">
                      <div className="featured-item feature-bg-box gray-bg text-center m-bot-0 inline-block radius-less box-estrategia">
                          <div className="icon">
                              <i className="fa fa-archive"></i>
                          </div>
                          <div className="desc p-top-30">
                              Investimento em operações estruturadas, recebíveis não padronizados e teses jurídicas pulverizadas
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
    <div
        className="full-width float-left"
        data-parallax="scroll"
        data-image-src="assets/img/parallax/fundo-jive-distressed-fim-II-cp-ie.jpg"
    >
        <div className="full-width float-left parallax-window">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info">
                            <h1 className="text-uppercase light-txt m-bot-0">JIVE DISTRESSED FIM CP</h1>
                        </div>
                        <div className="promo-btn">
                            <a href="/jive-distressed-fim-cp-ie" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window parallax-ibaro-cap">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info text-right">
                            <h1 className="text-uppercase light-txt m-bot-0">Jive Distressed FIM II CP</h1>
                        </div>
                        <div className="promo-btn">
                            <a href="/jive-distressed-fim-ii-cp-ie" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info">
                            <h1 className="text-uppercase light-txt m-bot-0">JIVE DISTRESSED FIM III CP</h1>
                        </div>
                        <div className="promo-btn">
                            <a href="/jive-distressed-fim-iii-cp " className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window parallax-ibaro-cap">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info text-right">
                            <h1 className="text-uppercase light-txt m-bot-0">JIVE DISTRESSED & SPECIAL SITS IV ADVISORY (PROFISSIONAL)</h1>
                        </div>
                        <div className="promo-btn">
                            <a href="https://hs.jiveinvestments.com/jive-distressed-special-sits-iv-advisory-profissional" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info">
                            <h1 className="text-uppercase light-txt m-bot-0">JIVE DISTRESSED & SPECIAL SITS IV ADVISORY (QUALIFICADO) III</h1>
                        </div>
                        <div className="promo-btn">
                            <a href="https://hs.jiveinvestments.com/jive-distressed-special-sits-iv-advisory-qualificado-iii" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window parallax-ibaro-cap">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info text-right">
                            <h1 className="text-uppercase light-txt m-bot-0">JIVE BOSSANOVA HIGH YIELD FIC FIM</h1>
                        </div>
                        <div className="promo-btn">
                            <a href="https://hs.jiveinvestments.com/bossanova" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info">
                            <h1 className="text-uppercase light-txt m-bot-0"> JIVE SOUL PREV </h1>
                        </div>
                       <div className="promo-btn">
                            <a href="https://hs.jiveinvestments.com/jive-soul-prev" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window parallax-ibaro-cap">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info text-right">
                            <h1 className="text-uppercase light-txt m-bot-0">Fundos Imobiliários (Mauá)
                            </h1>
                        </div>
                        <div className="promo-btn">
                            <a href="https://www.mauacapital.com" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div> 
                    </div>
                </div>
            </div>
        </div>

        <div className="full-width float-left parallax-window">
            <div className="container ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="promo-info">
                            <h1 className="text-uppercase light-txt m-bot-0"> JiveMaua FIC FI-Infra (JMBI11)</h1>
                        </div>
                       <div className="promo-btn">
                            <a href="https://hs.jivemaua.com.br/bossanovafiinfra" className="btn btn-medium btn-rounded btn-light-solid  text-uppercase"><Trans>SAIBA MAIS</Trans></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
      <div className="page-content" id="noticias-home">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="m-bot-0 inline-block">
                          <div className="text-center">
                              <h3 className="text-uppercase inline-block m-bot-0"><Trans>Notícias</Trans></h3>
                              <p className=" half-txt m-bot-50"><Trans>Confira as últimas notícias que separamos sobre a Jive e o Mercado</Trans></p>
                          </div>
                      </div>

                      <div className="post-grid">
                          <div className="col-md-4 col-sm-4">
                              <div className="post-single">
                                  <div className="post-img bottom">
                                      <img src={news1.attachment} alt="" />
                                  </div>
                                  <div className="post-desk pad-left-less">
                                      <div className="mid-align">
                                          <h4 className="text-uppercase">
                                              <a href={`/noticia-interna?q=${news1.id}`}>{news1.title}</a>
                                          </h4>
                                          <ul className="post-cat">
                                              <li><Trans>FONTE</Trans> {news1.source}
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-4 col-sm-4">
                              <div className="post-single">
                                  <div className="post-desk">
                                      <div className="mid-align">

                                          <h4 className="text-uppercase">
                                              <a href={`/noticia-interna?q=${news2.id}`}>{news2.title}</a>
                                          </h4>
                                          <ul className="post-cat">
                                              <li><Trans>FONTE</Trans> {news2.source}
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                                  <div className="post-img top">
                                      <img src={news2.attachment} alt="" />
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-4 col-sm-4 last">
                              <div className="post-single">
                                  <div className="post-img bottom">
                                      <img src={news3.attachment} alt="" />
                                  </div>
                                  <div className="post-desk ">
                                      <div className="mid-align">

                                          <h4 className="text-uppercase">
                                              <a href={`/noticia-interna?q=${news3.id}`}>{news3.title}</a>
                                          </h4>
                                          <ul className="post-cat">
                                              <li><Trans>FONTE</Trans> {news3.source}
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="text-center m-top-50 inline-block">
                              <a href="/jive-na-midia" className="p-read-more"><Trans>VER MAIS</Trans><i className="icon-arrows_slim_right"></i></a>
                          </div>
                      </div>

                  </div>
              </div>
          </div>

      </div>

          


      {/* <div className="page-content p-bot-0" id="video-home">
          <div className="container">
              <div className="row">

                  <div className="text-center ">
                      <h3 className="text-uppercase m-bot-50">MANIFESTO JIVE</h3>
                  </div>
                  <div className="col-md-8 col-md-offset-2">
                      <p className="video-fit m-bot-0 wow zoomIn">
                          <iframe title="" width="560" height="315" src="//www.youtube.com/embed/hVB4lj6MJjY" allowFullscreen></iframe>
                      </p>
                  </div>

              </div>
          </div>
      </div> */}
      {/* <div className="page-content" id="contato">
          <div className="container">
              <div className="row">
                  <div className="col-md-4 col-sm-5">
                      <h4 className="text-uppercase m-bot-15">ENDEREÇO</h4>
                      <p>Av,. Brigadeiro Faria Lima, 1485 <br />
                          18º andar - São Paulo | SP - Brasil<br />
                          CEP: 01452-002</p>
                      <address>
                          <h4 className="text-uppercase m-bot-15">PARA DÚVIDAS IMEDIATAS</h4>
                          <p className='fone'><span>+55 (11)</span> 3500-5055</p>
                      </address>
                  </div>

                  <div className="col-md-8 col-sm-7">
                      <h4 className="text-uppercase m-bot-15">Entre em contato</h4>
                      <p>Nos envie seus contatos, dúvidas e sugestões que em breve retornaremos. </p>

                      <form className="contact-comments m-top-0 js-Mailer" method="post">

                          <div className="row row-form">

                              <div className="col-md-6 col-sm-6 form-group">
                                  <label for="form-field-name">Nome *</label>
                                  <input type="text" name="name" id="form-field-name" className="form-control" maxLength="100" required data-error="You must enter name" />
                                  <div className="help-block with-errors"></div>
                              </div>

                              <div className="col-md-6 col-sm-6 form-group">
                                  <label for="form-field-email">E-mail *</label>
                                  <input type="email" name="email" id="form-field-email" className="form-control" maxLength="100" required data-error="Invalid email address!" />
                                  <div className="help-block with-errors"></div>
                              </div>

                              <div className="form-group col-md-6 col-sm-6">
                                  <label for="form-field-phone">Telefone</label>
                                  <input type="text" name="phone" id="form-field-phone" className="form-control" maxLength="100" />
                                  <div className="help-block with-errors"></div>
                              </div>

                              <div className="form-group col-md-6 col-sm-6">
                                  <label for="form-field-company">Empresa</label>
                                  <input type="text" name="company" id="form-field-company" className="form-control" maxLength="100" />
                                  <div className="help-block with-errors"></div>
                              </div>
                              <div className="form-group col-md-12 col-sm-12">
                                  <label for="form-field-subject">Assunto</label>
                                  <input type="text" name="subject" id="form-field-subject" className="form-control" maxLength="100" />
                                  <div className="help-block with-errors"></div>
                              </div>

                              <div className="form-group col-md-12 col-sm-12">
                                  <label for="form-field-comments">Mensagem</label>
                                  <textarea name="comments" id="form-field-comments" className="cmnt-text form-control" rows="6" maxLength="400"></textarea>
                                  <div className="help-block with-errors"></div>
                              </div>

                              <div className="form-group m-bot-0 col-md-12 col-sm-12">
                                  <button type="submit" className="btn btn-small btn-dark-solid ">ENVIAR</button>
                              </div>

                          </div>
                          <input type="hidden" name="id" value="FORM_ALT" />

                      </form>
                  </div>

              </div>
          </div>
      </div>

      <div id='grey-map' className="height-450"></div> */}
  </section>

    );
  }


export default Conteudo;
